import "../scss/app.scss";

// SVGs to make into sprite, paths relative to app.js before move
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../../flyingoxygen/jinja2/svgs/sprite.svg"
};

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/es/array/from";
import "core-js/features/promise";

// We manually include the DOM polyfills we believe are necesary.
import applyDomPolyfills from "./includes/dompolyfills";

applyDomPolyfills();

// Production code goes after here
import { ValidatedForm } from "@neonjungle/birdseed/forms/validatedform";
import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";
import { OutdatedBrowserNotice } from "@neonjungle/birdseed/browser/OutdatedBrowserNotice";

import initMap from "./includes/gmaps";
import menuActions from "./includes/menuActions";
import attributesForCSS from "./includes/attributesForCSS";
import updateClampedCSS from "./includes/updateClampedCSS";

// Should happen before the page has finished loading
// to update font sizes ASAP
updateClampedCSS();

document.addEventListener("DOMContentLoaded", function() {
	window.initMap = initMap;
	menuActions();
	attributesForCSS();

	new OutdatedBrowserNotice(false, browserOutdated => {
		if (browserOutdated) {
			document.body.classList.add("browser-is-outdated");
		}
	});

	document.body.classList.remove("preload");

	const validatedForms = document.querySelectorAll("form.l-form");
	validatedForms.forEach($f => new ValidatedForm($f));

	let mailchimpForm = document.querySelector("[data-mailchimp-form]");
	if (mailchimpForm) new AjaxForm(mailchimpForm);
});
