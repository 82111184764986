export default function attributesForCSS() {
	const inputs = document.querySelectorAll("input, textarea");

	inputs.forEach(el => {
		// Typing in an input or setting an el.value doesnt update the attribute in all browsers.
		// input[value=""] is a very useful CSS selector. This little snippit of code
		// makes sure the value attrubite always matches the element.value -JS
		el.setAttribute("value", el.value);

		el.addEventListener("keyup", function(e) {
			el.setAttribute("value", el.value);
			e.preventDefault();
		});

		// By setting the size of an input to 1 it removes the minimum width of that input.
		// This can be helpful in achieveing design components like searchbars that expand. -JS
		el.setAttribute("size", 1);
	});
}
